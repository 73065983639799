<template>
    <div class="con-wrap">
        <CustomerStageTablePopup v-if="pop.isShowStep" @close="pop.isShowStep=false"></CustomerStageTablePopup>
        <DoubleCheck1Popup :companyname="pop.cname" v-model="pop.isAble" v-if="pop.isShowCheck" v-on:close="pop.hideCheck"></DoubleCheck1Popup>

        <CarrotTitle title="고객사수정">
            <div class="btn-ibbox title-btnbox float-right">
                <button class="btn-default" @click="pop.showStep">고객사 단계 구분표</button>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사명</th>
                                <td>
                                    <input type="text" v-model.trim="mod.info.kname" class="w-300px float-left mr-5" maxlength="50">
                                    <button class="btn-default float-left h-30px" v-if="pop.ableShowCheck" @click="pop.showCheck">중복확인</button>
                                    <span class="ml-10">* 공백 없이 한글만 입력해주세요.</span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사 영문명</th>
                                <td>
                                    <input type="text" v-model.trim="mod.info.ename" class="w-300px" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 주소</th>
                                <td>
                                    <carrot-address v-if="mod.is_loaded" v-model:zipcode.sync="mod.info.zipcode" v-model:address.sync="mod.info.addr"></carrot-address>
                                    <input type="text" v-model.trim="mod.info.addr_sub" class="w-100per mt-5" placeholder="상세주소를 입력하세요.">
                                </td>
                            </tr>
                            <tr>
                                <th>대표자명</th>
                                <td>
                                    <input type="text" v-model.trim="mod.info.ceo_name" class="w-300px" maxlength="50">
                                </td>
                            </tr>
                            <tr>
                                <th>사업자번호</th>
                                <td>
                                    <input type="text" v-model="mod.info.bizno" class="w-200px">
                                </td>
                            </tr>
                            <tr>
                                <th>고객사 단계</th>
                                <td>
                                    <carrot-company-step v-if="mod.is_loaded" v-model="mod.info.step" class="w-200px"></carrot-company-step>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사 유형</th>
                                <td>
                                    <carrot-company-type v-if="mod.is_loaded" v-model="mod.info.company_type" class="w-200px"></carrot-company-type>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사 산업군</th>
                                <td>
                                    <carrot-industry v-if="mod.is_loaded" v-model="mod.info.industry_type" class="w-200px"></carrot-industry>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사 특이사항</th>
                                <td>
                                    <textarea class="w-100per h-100px" v-model.trim="mod.info.special_memo"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 변경사항</th>
                                <td>
                                    <textarea class="w-100per h-100px" v-model.trim="mod.mod_memo"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-left mt-30" @click="mod.doCancel">취소</button>
                    <button class="btn-default float-right mt-30" @click="mod.doSubmit">수정</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
// import Swal from 'sweetalert2'
import CarrotAddress from '@/components/common/CarrotAddress.vue'
import CarrotCompanyStep from '@/components/common/CarrotCompanyStep.vue'
import CarrotCompanyType from '@/components/common/CarrotCompanyType.vue'
import CarrotIndustry from '@/components/common/CarrotIndustry.vue'
import CustomerStageTablePopup from '@/components/popup/customerManagement/CustomerStageTablePopup.vue'
import DoubleCheck1Popup from '@/components/popup/customerManagement/DoubleCheck1Popup.vue'

export default {
  layout: 'customerManagement',
  components: {
        CarrotAddress,
        CarrotCompanyStep,
        CarrotCompanyType,
        CarrotIndustry,
        CustomerStageTablePopup, 
        DoubleCheck1Popup
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const pop = reactive({
            // Popup 노출 여부
            cname : "",
            isAble : "Y",
            isShowStep : false,
            isShowCheck: false,
            ableShowCheck : false,

            showStep : () => {
                pop.isShowStep = true;
            },

            showCheck : () => {
                pop.isShowCheck = true;
                pop.cname = mod.info.kname;
            },

            hideCheck : () => {
                pop.isShowCheck = false;
                console.log(pop.isAble);
            }
        });

        const mod = reactive({
            idx : 0,
            is_loaded : false,

            info : {
                kname    : "",
                ename    : "",
                zipcode  : "",
                addr     : "",
                addr_sub : "",
                ceo_name : "",
                bizno    : "",
                step     : "",
                company_type  : "",
                industry_type : "",
                special_memo  : ""
            },
            mod_memo : "",

            doSubmit : () => {
                let params = {
                    idx      : mod.idx,
                    kname    : mod.info.kname,
                    ename    : mod.info.ename,
                    zipcode  : mod.info.zipcode,
                    addr     : mod.info.addr,
                    addr_sub : mod.info.addr_sub,
                    ceo_name : mod.info.ceo_name,
                    bizno    : mod.info.bizno,
                    step     : mod.info.step,
                    company_type  : mod.info.company_type,
                    industry_type : mod.info.industry_type,
                    special_memo  : mod.info.special_memo,
                    mod_memo      : mod.mod_memo
                };

                if( !mod.info.kname.length ){
                    toast.error("고객사명을 입력해주세요.");
                    return;
                }
                if( !mod.info.ename.length ){
                  toast.error("고객사 영문명을 입력해주세요.");
                  return;
                }
                if( pop.isAble == "N" ||  pop.isAble == "" ){
                    toast.error("고객사명 중복확인을 해주세요.");
                    return;
                }
                if( !mod.info.addr_sub ){
                  toast.error("주소를 입력해주세요.");
                  return;
                }
                if( !mod.info.company_type || !mod.info.industry_type || !mod.info.special_memo ){
                    toast.error("필수 입력 항목을 작성하세요.");
                    return;
                }

                if( !mod.mod_memo.length ){
                    toast.error("변경사항을 입력해주세요.");
                    return;
                }

                axios.post("/rest/customermgr/modCustomer", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : "customerManagement-customerDBViewTab1-idx",
                            params : {
                                idx : mod.idx
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doCancel: () => {
                router.go(-1);
            },

            doSearch: () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/customermgr/getCustomerDBInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;

                        mod.is_loaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });
        
        watch(() => mod.info.kname, (nVal, oVal) => {
            if( nVal && oVal && nVal != oVal ) {
                pop.isAble = "N";
                pop.ableShowCheck = true;
                mod.info.kname = nVal.replace(/[^ㄱ-ㅎ가-힣]/gi, '');
            }
        });

        watch(() => mod.info.ename, (nVal, oVal) => {
            if( nVal && nVal != oVal ) {
                mod.info.ename = nVal.replace(/[^a-zA-Z '&]/gi, '');
            }
        });

        watch(() => mod.info.bizno, (nVal, oVal) => {
            if( nVal != oVal ) {
                let val = nVal.replace(/[^0-9]/gi, '');
                if( val.length <= 3 ){
                    mod.info.bizno = val;
                } else if( 3 < val.length && val.length <= 5 ){
                    mod.info.bizno = val.substr(0, 3) + '-' + val.substr(3, 2);
                } else if( 5 < val.length && val.length <= 10 ){
                    mod.info.bizno = val.substr(0, 3) + '-' + val.substr(3, 2) + '-' + val.substr(5,5);
                } else if( 10 < val.length ) {
                    mod.info.bizno = oVal;
                }
            }
        });

        onMounted(() => {
            // Mounted
            mod.idx = route.params.idx?route.params.idx:0;

            if ( !mod.idx ) {
                router.back(-1);
            }

            mod.doSearch();
        });

        return {pop, mod};
    }
}
</script>

<style lang="scss" scoped>
</style>